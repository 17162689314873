import React from "react";
import { OutlinedInput, Button, IconButton, Card, InputAdornment, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Container, } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { ArrowDown, uploadImage, arrowRight, togleFalse, togleTrue, cross, ArrowUp, } from "./assets";
import "../assets/Styles/Userprofile.css";
import CustomisableUserProfilesWebController from "./CustomisableUserProfilesWebController";
import CountryCodeModal from "../../../blocks/social-media-account-login/src/CountryCodeModal.web";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import { imageFalse, wright } from "../../dashboard/src/assets";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import Loader from "../../../components/src/Loader.web";
class CustomisableBusinessUserProfiles extends CustomisableUserProfilesWebController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const { workingHoursError, weekendHoursError } = this.state;
        const borderStyleworkingHours = this.getBorderStyle(workingHoursError);
        const borderStyleweekendHours = this.getBorderStyle(weekendHoursError);
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let setUpBusinessDropDownClassName = webAppDirection === "ltr" ? "dropdown-image" : "arabic-dropdown-image";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "profile-page-main-container" },
                React.createElement("div", { style: {
                        marginBottom: "40px",
                    } },
                    React.createElement(AfterLoginHeader, { navigation: this.props.navigation })),
                React.createElement(Container, { maxWidth: "lg" },
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement("div", { style: {
                                    alignItems: "center",
                                    display: "flex",
                                    fontFamily: "Poppins-Medium",
                                    fontSize: 24,
                                } }, t("Setup your business profile")),
                            React.createElement("div", null, t("The brand name your client will see. Your billing and legal name can be added later.")))),
                    React.createElement(Grid, { container: true, spacing: 6 },
                        React.createElement(Grid, { container: true, item: true, xs: 12, md: 6, style: { height: "fit-content" } },
                            React.createElement(Card, { className: "card-details-style" },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Business pictures(5 max)")),
                                    React.createElement(Card, { className: "profile-root-style-card", style: {
                                            backgroundColor: this.getCardBackGroundColor(this.state.showImages),
                                        } },
                                        React.createElement("div", null,
                                            React.createElement(Grid, { container: true, alignItems: "center", spacing: 3, justifyContent: "center", style: { overflow: "hidden" } },
                                                React.createElement(Grid, { spacing: this.state.businessImages.length ? 2 : 0, container: true, item: true, xs: 12, style: { height: "100%" } },
                                                    React.createElement(ImageUploading, { onChange: this.onChange, multiple: true, acceptType: ["jpg", "jpeg", "png"], value: this.state.businessImages, dataURLKey: "data_url" }, ({ imageList, onImageUpload, onImageRemove, isDragging, dragProps, }) => (React.createElement(React.Fragment, null, !this.state.showImages ? (React.createElement(Grid, { item: true, xs: 12, style: {
                                                            height: "100%",
                                                            textAlign: "center",
                                                            justifyContent: "center",
                                                            display: "flex",
                                                            borderRadius: 10,
                                                        } },
                                                        React.createElement(IconButton, Object.assign({ style: isDragging
                                                                ? { color: "red" }
                                                                : undefined, onClick: onImageUpload }, dragProps),
                                                            React.createElement("img", { src: uploadImage, alt: "" })))) : (React.createElement(React.Fragment, null,
                                                        React.createElement(Grid, { item: true, xs: 4 },
                                                            React.createElement("div", { style: { position: "relative" } },
                                                                React.createElement("img", { src: imageList[0].data_url, width: "100%", alt: "", style: {
                                                                        borderRadius: 10,
                                                                        objectFit: "cover",
                                                                    }, height: "280px" }),
                                                                React.createElement(IconButton, { style: {
                                                                        position: "absolute",
                                                                        right: 0,
                                                                        top: 0,
                                                                    }, onClick: () => onImageRemove(0) },
                                                                    React.createElement("img", { src: imageFalse, width: "30", height: "30" })))),
                                                        React.createElement(Grid, { container: true, spacing: 2, item: true, xs: 8 },
                                                            imageList
                                                                .slice(1)
                                                                .map((item, index) => (React.createElement(Grid, { item: true, xs: 6 },
                                                                React.createElement("div", { style: { position: "relative" } },
                                                                    React.createElement("img", { src: item.data_url, width: "100%", alt: "", style: {
                                                                            borderRadius: 10,
                                                                            objectFit: "cover",
                                                                        }, height: "130px" }),
                                                                    React.createElement(IconButton, { style: {
                                                                            position: "absolute",
                                                                            right: 0,
                                                                            top: 0,
                                                                        }, onClick: () => onImageRemove(index + 1) },
                                                                        React.createElement("img", { src: imageFalse, alt: "", width: "30", height: "30" })))))),
                                                            imageList.length < 5 && (React.createElement(Grid, { item: true, xs: 6, style: {
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "150px",
                                                                    display: "flex",
                                                                } },
                                                                React.createElement(IconButton, Object.assign({}, dragProps, { onClick: onImageUpload }),
                                                                    React.createElement("img", { src: uploadImage })))))))))))))),
                                    React.createElement("div", { style: {
                                            color: "#757575",
                                            fontSize: "12px",
                                            marginTop: "15px",
                                            width: "100%",
                                            display: "flex",
                                        } }, t("HD images are preferable")),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessImagesError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { style: { color: "#2C2C2E" }, className: "form-label-profile" }, t("Business name *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, value: this.state.businessName, type: "text", className: "form-input-style", id: "txtInputBusinessname", onChange: (e) => this.setInputValue(e.target.value), error: this.state.businessNameError, onBlur: (e) => this.validateBusinessName(), inputProps: {
                                            min: 0,
                                            maxLength: 50,
                                            style: { outline: "none", boxShadow: "none" },
                                        } }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessNameError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { style: { color: "#2C2C2E" }, className: "form-label-profile" }, t("Business name arabic")),
                                    React.createElement(OutlinedInput, { value: this.state.arabicBusinessName, type: "text", fullWidth: true, id: "txtInputArabicBusinessname", className: "form-input-style", onChange: (e) => this.setArabicInputValue(e.target.value), inputProps: {
                                            style: { outline: "none", boxShadow: "none" },
                                            maxLength: 50,
                                            min: 0,
                                        } }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.arabicBusinessNameError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Owner name *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, id: "ownername", type: "text", value: this.state.businessOwner, onBlur: (e) => this.validateBusinessOwner(), onChange: (e) => {
                                            if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                                                this.setState({
                                                    businessOwnerError: "",
                                                    businessOwner: e.target.value,
                                                });
                                            }
                                        }, className: "form-input-style", error: this.state.businessOwnerError, inputProps: {
                                            min: 0,
                                            maxLength: 50,
                                            style: { outline: "none", boxShadow: "none" },
                                        } }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessOwnerError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Business email *")),
                                    React.createElement(OutlinedInput, { fullWidth: true, id: "businessEmail", type: "text", value: this.state.businessEmail, onChange: this.handleBusinessEmailChange, className: "form-input-style", error: this.state.businessEmailError, inputProps: {
                                            min: 0,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateBusinessEmail(), placeholder: "growyourbusiness@example.com" }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.businessEmailError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Website (Optional)")),
                                    React.createElement(OutlinedInput, { error: this.state.websiteError, fullWidth: true, onChange: this.handleWebisteChange, value: this.state.website, type: "text", id: "website", placeholder: "www.example.com", inputProps: {
                                            min: 0,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, className: "form-input-style", onBlur: (e) => this.validateWebsite() }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.websiteError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Contact Number *")),
                                    React.createElement(OutlinedInput, { error: this.state.contactError, onChange: this.handleContactNumberChange, fullWidth: true, type: "text", value: this.state.contact, id: "phoneNumber", name: "phoneNumber", inputProps: {
                                            min: 0,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, onBlur: (e) => this.validateContact(), className: "form-input-style-contact-number", startAdornment: React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(IconButton, { style: {
                                                    height: 48,
                                                    width: 66,
                                                    cursor: "pointer",
                                                    justifyContent: "center",
                                                    borderRight: webAppDirection === "ltr"
                                                        ? "1px solid #979797"
                                                        : "",
                                                    borderLeft: webAppDirection === "rtl"
                                                        ? "1px solid #979797"
                                                        : "",
                                                    borderRadius: "0%",
                                                }, onClick: () => this.handleCountryCodeChange(), id: "countrycode" },
                                                React.createElement("span", { style: {
                                                        color: "#2c2c2e",
                                                    }, className: "country-code-text-style" }, this.state.countryCode))) }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.contactError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("CRN *")),
                                    React.createElement(OutlinedInput, { error: this.state.crnError, fullWidth: true, value: this.state.crn, type: "text", onChange: this.handleCRNChange, className: "form-input-style", inputProps: {
                                            min: 0,
                                            maxLength: 10,
                                            style: { outline: "none", boxShadow: "none" },
                                        }, id: "crn", onBlur: (e) => this.validateCRN() }),
                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.crnError)))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { className: "form-label-profile", style: { color: "#2C2C2E" } }, t("Service provided")),
                                    React.createElement("div", { className: "form-select-input-style-profile" },
                                        React.createElement("div", { className: "custom-select-profile" },
                                            React.createElement("select", { onClick: () => {
                                                    this.handleSelectToggle("isSelectOpen");
                                                }, id: "outlined-select", onChange: (event) => {
                                                    this.setServiceID(event.target.value);
                                                }, style: { direction: "inherit" } }, this.state.providedService.map((option) => (React.createElement("option", { key: option.id, style: {
                                                    fontFamily: "Poppins-Medium, sans-serif",
                                                }, value: option.id }, t(option.name))))),
                                            React.createElement("img", { src: this.state.isSelectOpen ? ArrowUp : ArrowDown, alt: "Custom Icon", className: setUpBusinessDropDownClassName })))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { style: { color: "#2C2C2E" }, className: "form-label-profile" }, t("About us (Optional)")),
                                    React.createElement(OutlinedInput, { id: "aboutMultiline", className: "form-input-style", value: this.state.aboutUsText, fullWidth: true, style: {
                                            marginTop: "20px",
                                            height: "150px",
                                            fontFamily: "Poppins, sans-serif",
                                            border: "1px solid #757575",
                                            flexDirection: "column",
                                        }, rows: 4, multiline: true, placeholder: t("Write about your business…"), onChange: (e) => {
                                            if (/^[a-zA-Z .]*$/.test(e.target.value)) {
                                                this.setState({ aboutUsText: e.target.value });
                                            }
                                        }, type: "text", inputProps: {
                                            min: 0,
                                            maxLength: 250,
                                            style: {
                                                marginLeft: 5,
                                                outline: "none",
                                                boxShadow: "none",
                                            },
                                        } })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement("span", { style: { color: "#2C2C2E" }, className: "form-label-profile" }, t("About us (Optional) arabic")),
                                    React.createElement(OutlinedInput, { id: "aboutMultilineArabic", value: this.state.aboutUsTextArabic, className: "form-input-style", placeholder: t("Write about your business in arabic…"), fullWidth: true, style: {
                                            flexDirection: "column",
                                            height: "150px",
                                            marginTop: "20px",
                                            border: "1px solid #757575",
                                            fontFamily: "Poppins, sans-serif",
                                        }, multiline: true, rows: 4, onChange: this.handleArabicAboutUs, type: "text", inputProps: {
                                            min: 0,
                                            maxLength: 250,
                                            style: {
                                                marginLeft: 5,
                                                outline: "none",
                                                boxShadow: "none",
                                            },
                                        } })))),
                        React.createElement(Grid, { style: { height: "fit-content" }, container: true, item: true, xs: 12, md: 6 },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { marginBottom: 30 } }, t("Payment")),
                                React.createElement(Card, { className: "card-details-style" },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Bank account *")),
                                        React.createElement(OutlinedInput, { type: "text", id: "add-bank-account", fullWidth: true, readOnly: true, value: this.state.bankName, error: this.state.bankDetailsError, onClick: () => {
                                                this.setStateValue();
                                            }, placeholder: t("Add bank details"), className: "form-input-style", inputProps: { min: 0 }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, null,
                                                    React.createElement("img", { src: arrowRight, alt: "logo", height: "24px", width: "24px" }))) }),
                                        React.createElement("div", { style: {
                                                marginTop: 15,
                                            } },
                                            React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.bankDetailsError))))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E", marginTop: 40 } }, t("Payment options")),
                                        React.createElement("div", { style: { marginTop: 10, display: "flex" } },
                                            React.createElement(Checkbox, { name: "PayAtShop", id: "payatshop", color: "primary", checked: this.state.enablePayAtShop, onChange: (e) => {
                                                    this.handlePaymentSelection("payAtShop");
                                                }, className: "terms-checkbox-input" }),
                                            React.createElement("span", { className: "payment-options-lable" }, t("Pay at shop(card or cash)"))),
                                        React.createElement("div", null,
                                            React.createElement(Checkbox, { onChange: () => {
                                                    this.handlePaymentSelection();
                                                }, name: "CardPayment", checked: this.state.enableCardPayment, className: "cardpayemnt", color: "primary" }),
                                            React.createElement("span", { className: "payment-options-lable" }, t("Card payment"))),
                                        React.createElement("div", { style: {
                                                marginLeft: 10,
                                            } },
                                            React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.paymentOptionError))))))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { margin: "30px 0", marginLeft: 10 } }, t("Business working hours")),
                                React.createElement(Card, { className: "card-details-style" },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("div", { className: "input-container-left-edit-profile" },
                                            React.createElement("div", { className: "input-container-button-edit-profile" },
                                                React.createElement(ToggleButtonGroup, null,
                                                    React.createElement(ToggleButton, { onClick: () => {
                                                            this.setState({ getSelectionMode: "weekdays" });
                                                        }, style: {
                                                            borderRadius: 24,
                                                            width: 150,
                                                            height: 44,
                                                            fontWeight: 500,
                                                            fontSize: 14,
                                                            border: 0,
                                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                                                            color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                                            textTransform: "none",
                                                        }, "aria-label": "left aligned", value: "left", id: "btnSelect", className: "weekdays" },
                                                        React.createElement("span", { className: "fontfamily-profile" }, t("Weekdays"))),
                                                    React.createElement(ToggleButton, { onClick: () => {
                                                            this.setState({ getSelectionMode: "weekends" });
                                                        }, style: {
                                                            border: 0,
                                                            borderRadius: 24,
                                                            width: 150,
                                                            textTransform: "none",
                                                            fontWeight: 500,
                                                            height: 44,
                                                            fontSize: 14,
                                                            color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                                            backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                                                            fontFamily: "Poppins",
                                                        }, "aria-label": "centered", value: "center", id: "weekEnds" },
                                                        React.createElement("span", { className: "fontfamily-profile" }, t("Weekends"))))),
                                            this.state.getSelectionMode === "weekdays" ? (React.createElement(Grid, { container: true, spacing: 3 },
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { className: "profile-form-sub-header", style: { marginTop: 30, marginBottom: 15 } }, t("From *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { id: "SelectWeekHours", onChange: (event) => {
                                                                this.onWorkingFromSelection(event.target.value);
                                                            }, onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                                            }, style: {
                                                                border: borderStyleworkingHours,
                                                                borderRadius: 8,
                                                                direction: "inherit",
                                                            }, value: this.state.workingFrom }, this.state.workingSlot.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { value: option, key: count, disabled: this.optionDisable(option), id: count },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { src: this.state.isSelectOpenWeekHoursFrom
                                                                ? ArrowUp
                                                                : ArrowDown, className: setUpBusinessDropDownClassName, alt: "CustomIcon" }))),
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { style: { marginTop: 30, marginBottom: 15 }, className: "profile-form-sub-header" }, t("To *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { id: "mySelectTo", value: this.state.workingTo, onChange: (event) => {
                                                                this.setState({
                                                                    workingTo: event.target.value,
                                                                    workingHoursError: "",
                                                                });
                                                            }, disabled: this.state.isWorkingToDisable, onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                                            }, style: {
                                                                border: borderStyleworkingHours,
                                                                direction: "inherit",
                                                                borderRadius: 8,
                                                            } }, this.state.workingSlotTo.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { disabled: this.optionDisable(option), key: count, id: count, value: option },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { className: setUpBusinessDropDownClassName, src: this.state.isSelectOpenWeekHoursTo
                                                                ? ArrowUp
                                                                : ArrowDown, alt: "Custom-Icon" }))))) : (React.createElement(Grid, { container: true, spacing: 3 },
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { className: "profile-form-sub-header", style: { marginTop: 30, marginBottom: 15 } }, t("From *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { id: "mySelect", onChange: (event) => {
                                                                this.onWeekendFromSelection(event.target.value);
                                                            }, onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                                            }, style: {
                                                                direction: "inherit",
                                                                borderRadius: 8,
                                                                border: borderStyleweekendHours,
                                                            }, value: this.state.weekendFrom }, this.state.workingSlot.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { disabled: this.optionDisable(option), key: count, id: count, value: option },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { src: this.state.isSelectOpenWeekEndsFrom
                                                                ? ArrowUp
                                                                : ArrowDown, className: setUpBusinessDropDownClassName, alt: "custom-icon" }))),
                                                React.createElement(Grid, { item: true, xs: 6 },
                                                    React.createElement("div", { className: "profile-form-sub-header", style: { marginTop: 30, marginBottom: 15 } }, t("To *")),
                                                    React.createElement("div", { className: "custom-select-profile" },
                                                        React.createElement("select", { id: "mySelect", value: this.state.weekendTo, disabled: this.state.isWeekendToDisable, style: {
                                                                border: borderStyleweekendHours,
                                                                direction: "inherit",
                                                                borderRadius: 8,
                                                            }, onClick: () => {
                                                                this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                                            }, onChange: (event) => {
                                                                this.setState({
                                                                    weekendTo: event.target.value,
                                                                    weekendHoursError: "",
                                                                });
                                                            } }, this.state.weekendSlotTo.map((option, count) => {
                                                            let [time, amPm,] = this.showTimeInUppercase(option).split(" ");
                                                            return (React.createElement("option", { key: count, disabled: this.optionDisable(option), id: count, value: option },
                                                                time,
                                                                " ",
                                                                t(amPm)));
                                                        })),
                                                        React.createElement("img", { src: this.state.isSelectOpenWeekEndsTo
                                                                ? ArrowUp
                                                                : ArrowDown, className: setUpBusinessDropDownClassName, alt: "Custom Icon" }))))),
                                            React.createElement("div", { className: "input-container-item-time", style: {
                                                    direction: "inherit",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } },
                                                this.state.workingHoursError && (React.createElement("div", { style: {
                                                        marginBottom: "1%",
                                                        marginTop: "1%",
                                                        position: "relative",
                                                    } },
                                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.state.workingHoursError)))),
                                                this.state.weekendHoursError && (React.createElement("div", { style: {
                                                        marginTop: "1%",
                                                        marginBottom: "1%",
                                                        position: "relative",
                                                    } },
                                                    React.createElement("span", { className: "profile-error-message-text" }, t(this.state.weekendHoursError))))),
                                            React.createElement("div", { style: { margin: "20px 0" } },
                                                React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Working Days"))),
                                            React.createElement(Grid, { container: true, spacing: 2 }, this.state.workingDaysData.map((item) => {
                                                return (React.createElement(Grid, { item: true, xs: 4, key: item.id },
                                                    React.createElement(Button, { onClick: () => {
                                                            this.handleWorkingHoursSelection(item.id);
                                                        }, fullWidth: true, color: "primary", style: {
                                                            borderRadius: "27px",
                                                            height: "54px",
                                                            fontSize: "18px",
                                                            padding: 0,
                                                            border: 0,
                                                            background: 0,
                                                            color: this.handleColor(item),
                                                            backgroundColor: this.handleBackgroundColor(item),
                                                            textTransform: "capitalize",
                                                        }, className: "button-text" },
                                                        React.createElement("span", { className: "fontfamily-profile" }, t(item.day)))));
                                            })),
                                            React.createElement("div", { style: { marginTop: 20 } }, React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessage()))))))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", { className: "profile-header-main-item-text", style: { margin: "30px 0", marginLeft: 10 } }, t("Team members")),
                                React.createElement(Card, { className: "card-details-style" },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("div", { className: "team-icon-edit-profile" },
                                            React.createElement("div", { className: "switch-icon" },
                                                React.createElement("input", { type: "checkbox", onChange: () => {
                                                        this.setState({
                                                            allowTeam: !this.state.allowTeam,
                                                        });
                                                    }, id: "react-switch-new", className: "react-switch-checkbox", checked: this.state.allowTeam }),
                                                React.createElement("label", { className: "react-switch-label", htmlFor: "react-switch-new" },
                                                    React.createElement("img", { src: this.getTogle(this.state.allowTeam, togleTrue, togleFalse), width: "42px", height: "24px", alt: "logo" }))),
                                            React.createElement("div", { className: "team-members" },
                                                React.createElement("span", { style: { fontSize: "18px" }, className: "form-label-team" }, t("Show staff members to the customers")),
                                                React.createElement("span", { style: { color: "#757575", fontSize: 14 }, className: "form-label-team" }, t("Allow the customer to make the bookings with a team member."))))))),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement("div", null,
                                    React.createElement("div", null,
                                        React.createElement(Button, { color: "primary", fullWidth: true, onClick: () => {
                                                this.validateBusinessSetup();
                                            }, className: "edit-profile-next-Step-button" }, t("Next Step"))))))),
                    this.state.countryCodeModal && (React.createElement(CountryCodeModal, { open: this.state.countryCodeModal, onSelect: this.handleCountryCodeChange })),
                    React.createElement(Dialog, { PaperProps: {
                            style: {
                                height: 700,
                                boxShadow: "none",
                                width: 491,
                                borderRadius: "24px",
                            },
                        }, id: "bank_account_dialoge", open: this.state.openBankDetailsDialog, onKeyDown: this.handleKeyDown, tabIndex: 0 },
                        React.createElement(DialogTitle, { id: "simple-dialog-title", style: { marginTop: 20, textAlign: "center" } },
                            React.createElement("span", { className: "dialog-label", style: { fontSize: "20px", lineHeight: "24px" } }, t("Add Bank Details"))),
                        React.createElement(DialogContent, { ref: this.containerRef },
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile("Note: Please enter bank details in English only.")))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Full name")),
                                React.createElement(OutlinedInput, { onChange: (e) => this.handleAccountInformationChange(e, "accountOwnerFullName", "accountOwnerNameError"), error: this.state.accountOwnerNameError, id: "accountOwnerFullName", type: "text", className: "dialog-input-style", onBlur: () => this.validateName(), value: this.state.accountOwnerFullName, inputProps: {
                                        min: 0,
                                        maxLength: 50,
                                        style: { marginLeft: 5 },
                                    } }),
                                React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.accountOwnerNameError)))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Bank name")),
                                React.createElement(OutlinedInput, { value: this.state.bankName, onChange: (e) => this.handleAccountInformationChange(e, "bankName", "bankNameError"), error: this.state.bankNameError, id: "bankname", inputProps: {
                                        min: 0,
                                        maxLength: 50,
                                        style: { marginLeft: 5 },
                                    }, className: "dialog-input-style", type: "text", onBlur: () => this.validateBankName() }),
                                React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.bankNameError)))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Bank account number")),
                                React.createElement(OutlinedInput, { value: this.state.accountNumber, onChange: (e) => this.handleAccountNumberInput(e, "accountNumber", "accountNumberError"), error: this.state.accountNumberError, id: "accountNumber", className: "dialog-input-style", type: "text", onBlur: () => this.validateAccountNumber(), inputProps: {
                                        min: 0,
                                        maxLength: 25,
                                        style: { marginLeft: 5 },
                                    } }),
                                React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.accountNumberError)))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Confirm bank account number")),
                                React.createElement(OutlinedInput, { value: this.state.confirmAccountNumber, error: this.state.confirmAccountNumberError, onChange: (e) => this.handleAccountNumberInput(e, "confirmAccountNumber", "confirmAccountNumberError"), id: "confirmAccountNumber", type: "text", inputProps: {
                                        min: 0,
                                        maxLength: 25,
                                        style: { marginLeft: 5 },
                                    }, className: "dialog-input-style", onBlur: () => this.validateConfirmAccountNumber() }),
                                React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.confirmAccountNumberError)))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "dialog-label" }, t("Purpose of payment")),
                                React.createElement(OutlinedInput, { id: "purposeofpayment", value: this.state.purposeOfPayment, type: "text", onChange: (e) => this.handleAccountInformationChange(e, "purposeOfPayment", "purposeOfPaymentError"), error: this.state.purposeOfPaymentError, onBlur: () => this.validatePurpose(), className: "dialog-input-style", inputProps: {
                                        min: 0,
                                        maxLength: 90,
                                        style: { marginLeft: 5 },
                                    } }),
                                React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.purposeOfPaymentError))))),
                        React.createElement(DialogActions, null,
                            React.createElement("div", { style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                    marginTop: 40,
                                } },
                                React.createElement("div", { className: "profile-button-container" },
                                    React.createElement(Button, { className: "profile-row-button", style: {
                                            marginLeft: 20,
                                        }, onClick: this.handleBankPopupCancelButton, variant: "outlined", id: "cancel-btn" }, t("Cancel"))),
                                React.createElement("div", { className: "profile-button-container" },
                                    React.createElement(Button, { className: "profile-row-button", style: {
                                            color: "#ffffff",
                                            backgroundColor: "#1e5fea",
                                            marginRight: 60,
                                            marginLeft: 20,
                                        }, id: "save-btn", onClick: () => {
                                            this.handleSave();
                                        }, variant: "outlined" }, t("Save")))))),
                    React.createElement(Dialog, { open: this.state.openAddedDetailsDialog, PaperProps: {
                            style: {
                                height: 345,
                                boxShadow: "none",
                                width: 410,
                                borderRadius: "24px",
                            },
                        } },
                        React.createElement("span", { style: { textAlign: "right", marginTop: 30, marginRight: 30 } },
                            React.createElement(IconButton, { "aria-label": "upload picture", color: "primary", component: "span", onClick: () => {
                                    this.setState({ openAddedDetailsDialog: false });
                                }, id: "close-dialog" },
                                React.createElement("img", { src: cross, width: "24px", alt: "logo", height: "24px" }))),
                        React.createElement("div", { style: {
                                marginLeft: 50,
                                marginBottom: 20,
                                marginTop: 20,
                                marginRight: 50,
                                textAlign: "center"
                            } },
                            React.createElement("img", { src: wright, width: "120px", height: "120px", alt: "logo" })),
                        React.createElement("span", { style: {
                                fontSize: 20,
                                textAlign: "center",
                                fontFamily: "Poppins-Medium, sans-serif",
                            } }, this.saveBankDetailssuccess(t)),
                        React.createElement("span", { style: {
                                textAlign: "center",
                                fontFamily: "Poppins-Medium, sans-serif",
                                fontSize: 20,
                            } }, this.saveBankDetailssuccessSpantwo(t)))))));
    }
}
//@ts-ignore
export default withTranslation()(CustomisableBusinessUserProfiles);
